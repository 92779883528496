import React from 'react'
import styled from '@emotion/styled'

const ListContainer = styled.div`
  /* border: 1px dotted #ccc; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 65rem;
  padding-bottom: 1rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    flex-direction: column;
    margin-top: -1rem;
    flex-wrap: initial;
  }
`

const SermonList = props => {
    return <ListContainer>{props.children}</ListContainer>
}

export default SermonList
