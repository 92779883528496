import React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { startCase } from 'lodash'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import Header from '../components/SermonPage/Header'
import BreadCrumb from '../components/SermonPage/BreadCrumb'
import MonthlyLinks from '../components/SermonPage/MonthlyLinks'
import SermonList from '../components/SermonPage/SermonList'
import SermonCard from '../components/SermonPage/SermonCard'
import { SUNDAY_SERVICE_BASE, SERIES_BASE, MONTHLY_BASE } from '../routes'
import { SUNDAY_SERVICE_TITLE, WEEKDAY_SERVICE_TITLE, MONTHLY_TITLE, MONTH_NAMES } from '../constants'

const YearTitle = styled.div`
  font-size: 1.5em;
  margin-bottom: 0.5rem;
  text-align: center;
  color: ${props => props.theme.colors.textGrey};
  word-break: keep-all;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 14px;
  }
`

const MonthTitle = styled.h1`
  color: black;
  font-family: "Apple SD Gothic Neo";
  font-size: 2.5em;
  line-height: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  word-break: keep-all;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 1.5em;
    line-height: 1.5rem;
  }
`

function getMonthName(month) {
  let idx = parseInt(month.slice(5, 7)) - 1
  return MONTH_NAMES[idx]
}

const Monthly = ({ data, pageContext }) => {
  const { basePath, headerTitle, previous, next, month } = pageContext
  const year = month.slice(0, 4)
  const monthName = getMonthName(month)
  const title = (basePath === SUNDAY_SERVICE_BASE) ? SUNDAY_SERVICE_TITLE : WEEKDAY_SERVICE_TITLE
  const sermons = (basePath === SUNDAY_SERVICE_BASE) ? data.allContentfulSermonSunday.edges : data.allContentfulSermonWeekday.edges
  const routes = [{ slug: basePath, label: title }, { slug: `${basePath}/${MONTHLY_BASE}`, label: MONTHLY_TITLE }, { slug: `${basePath}/${MONTHLY_BASE}/${month}`, label: monthName + " " + year }]

  return (
    <Layout>
      <SEO title={startCase(basePath)} />
        <Header title={headerTitle} height={'20vh'} />
        <Container>
          <BreadCrumb routes={routes} />
          <YearTitle>{year}</YearTitle>
          <MonthTitle>{monthName}</MonthTitle>
          <SermonList>
            {sermons
              .filter(({ node: sermon }) => (sermon.mediaUrl))
              .map(({ node: sermon }) => (
                <SermonCard key={sermon.id} {...sermon} sermonBasePath={basePath} seriesBasePath={SERIES_BASE} routes={routes} />
            ))}
          </SermonList>
          <MonthlyLinks previous={previous} next={next} link={`${basePath}/${MONTHLY_BASE}`} />
        </Container>
    </Layout>
  )
}

export const query = graphql`
  query($month: Date!, $endMonth: Date!) {
    allContentfulSermonSunday(filter: { date: { gt: $month, lt: $endMonth, ne: null } }) {
      edges {
        node {
          id
          title
          dateSlug: date(formatString: "YYYY-MM-DD")
          mediaUrl
          speaker
          verse
          series {
            ... on ContentfulSeries {
              name
              slug
            }
          }
        }
      }
    }
    allContentfulSermonWeekday(filter: { date: { gt: $month, lt: $endMonth, ne: null } }) {
      edges {
        node {
          id
          title
          dateSlug: date(formatString: "YYYY-MM-DD")
          mediaUrl
          speaker
          verse
          series {
            ... on ContentfulSeries {
              name
              slug
            }
          }
        }
      }
    }
  }
`

export default Monthly
