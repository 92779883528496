import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { SUNDAY_SERVICE_BASE } from '../../routes'
import PlaySermon from '../../../static/images/playsermon.svg'
import PlaySermonWeekday from '../../../static/images/playsermonweekday.svg'

const Card = styled.li`
  border: 2px solid ${props => props.sunday ? props.theme.colors.green : props.theme.colors.text};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 28rem;
  min-height: 15rem;
  margin: 1rem;
  padding: 1rem;
  background: ${props => props.sunday ? `linear-gradient(133.88deg, #FFFFFF 50%, rgba(217,252,244,0.58) 70%, rgba(191,244,214,0.47) 80%, rgba(170,237,190,0.38) 100%)` : `linear-gradient(133.88deg, #FFFFFF 50%, rgba(217,241,252,0.58) 70%, rgba(171,170,237,0.38) 100%);`};
  word-break: keep-all;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    width: 100%;
    min-height: 14rem;
    padding: 0.75rem 0;
    margin: 0.75rem;
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.text};
    font-weight: bold;
  }
`
const TitleContainer = styled.div`
  flex-grow: 1;
`
const Title = styled.h2`
  font-size: 1.5em;
  font-weight: 600;
  color: ${props => props.theme.colors.text};
  text-transform: capitalize;
  margin: 0.5rem 1rem;
  line-height: 2rem;
`
const SubTitle = styled.h4`
  font-size: 1em;
  margin: 0.3rem 1rem;
  color: ${props => props.theme.colors.text};
  vertical-align: middle;
  a {
    color: ${props => props.sunday ? props.theme.colors.green : props.theme.colors.yellow} !important;
    text-decoration: none;
    font-weight: bold;
  }
`
const Button = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  width: 8rem;
  margin: 0.3rem 1rem;
  text-align: center;
  padding: 0.5rem;
  border-radius: 1.25rem;
  border: 2px solid ${props => props.sunday ? props.theme.colors.green : props.theme.colors.text};
  font-size: 1rem;
  &:hover {
    background-color: ${props => props.sunday ? `rgb(67 182 73 / 25%)` : `rgb(255 193 81 / 25%)`};
  }
  transition: background-color 0.3s ease;
  cursor: pointer;
`
const SermonPlayButton = styled(PlaySermon)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
`
const SermonPlayButtonWeekday = styled(PlaySermonWeekday)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
`

const SermonCard = ({ title, dateSlug, speaker, verse, series, ...props }) => {
  const isSunday = props.sermonBasePath === SUNDAY_SERVICE_BASE;
  return (
    <Card sunday={isSunday}>
      <TitleContainer>
        <Title>{title}</Title>
        <SubTitle>{dateSlug}&emsp;|&emsp;{speaker}</SubTitle>
        <SubTitle>{verse}</SubTitle>
        {series && (
          <SubTitle sunday={isSunday}>
            <Link to={`${props.sermonBasePath}/${props.seriesBasePath}/${series.slug}/`} state={{routes: props.routes}}>
              {series.name}
            </Link>
          </SubTitle>
        )}
      </TitleContainer>
      <Link to={`${props.sermonBasePath}/${dateSlug}/`} state={{routes: props.routes}}>
        <Button sunday={isSunday}>
          {isSunday 
            ? <SermonPlayButton/>
            : <SermonPlayButtonWeekday/>
          }
          설교듣기
        </Button>
      </Link>
    </Card>    
  )
}

export default SermonCard
