import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { MONTH_NAMES } from '../../constants'

const MonthlyLinksContainer = styled.div`
  display: flex; 
  flex-wrap: wrap; 
  flex-direction: row;
  justify-content: space-between;
  margin: 1.5rem auto;
  max-width: 59rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    margin: 2rem auto 0;
  }
  a {
    display: flex;
    flex-flow: column;
    color: ${props => props.theme.colors.green};
    font-size: 1.5rem;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      /* background: ${props => props.theme.colors.highlight}; */
    }
    @media screen and (max-width: ${props => props.theme.responsive.medium}) {
      font-size: 14px;
    }
  }
`

const MonthlyLinks = ({ previous, next, link }) => {
  let prevButton = <div></div>
  let nextButton = <div></div>

  if (previous) {
    const prevMonth = MONTH_NAMES[parseInt(previous.slice(5, 7))-1] + " " + previous.slice(0, 4)
    prevButton = 
      <Link to={`${link}/${previous}`}>
        &#60; {prevMonth}
      </Link>
  }

  if (next) {
    const nextMonth = MONTH_NAMES[parseInt(next.slice(5, 7))-1] + " " + next.slice(0, 4)
    nextButton = 
      <Link to={`${link}/${next}`}>
        {nextMonth} &#62;
      </Link>
  }

  return (
    <MonthlyLinksContainer>
      {prevButton}
      {nextButton}
    </MonthlyLinksContainer>
  )
}

export default MonthlyLinks
